import React from "react";
import classes from './Award.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/PromotionsPage/award.jpg';

const Award = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-140`}>
                        {t('promotions.award.title')}
                    </h2>
                    <h3 className={`${classes.subtitle} font-70`}>
                        {t('promotions.award.subtitle')}
                    </h3>
                    <p className={`${classes.text} font-17`}>
                        <Trans>
                            {t('promotions.award.text')}
                        </Trans>
                    </p>
                </div>
                <div className={classes.imageWrap}>
                    <img className={classes.image} src={image} alt='' />
                </div>
            </div>
        </section>
    );
};

export default Award;
