import React from "react";
import classes from './Choose.module.scss';
import { useTranslation } from "react-i18next";

const cards = [
    {
        heading: '8000+',
        text: 'home.choose.cards.card_01.text',
    },
    {
        heading: 'home.choose.cards.card_02.title',
        text: 'home.choose.cards.card_02.text',
    },
    {
        heading: 'home.choose.cards.card_03.title',
        text: 'home.choose.cards.card_03.text',
    },
];

const Choose = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.head}>
                    <h2 className={`${classes.title} font-70`}>
                        {t('home.choose.title')}
                    </h2>
                    <h3 className={`${classes.subtitle} font-50`}>
                        {t('home.choose.subtitle')}
                    </h3>
                </div>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <h4 className={`${classes.heading} font-50`}>
                                {t(card.heading)}
                            </h4>
                            <p className={`${classes.text} font-17`}>
                                {t(card.text)}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Choose;
