import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";
import image from '../../../assets/img/IpoPage/banner.jpg';

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.wrap}>
                <div className={classes.head}>
                    <Fade top>
                        <h1 className={`${classes.title} font-140`}>
                            {t('ipo.banner.title')}
                        </h1>
                    </Fade>
                    <Fade right>
                        <h2 className={`${classes.subtitle} font-70`}>
                            {t('ipo.banner.subtitle')}
                        </h2>
                    </Fade>
                    <Fade left>
                        <h3 className={`${classes.desc} font-50`}>
                            {t('ipo.banner.desc')}
                        </h3>
                    </Fade>
                </div>
                <p className={classes.text}>
                    {t('ipo.banner.text')}
                </p>
                <Fade right>
                    <img className={classes.image} src={image} alt='' />
                </Fade>
            </div>
        </section>
    );
};

export default Banner;
