import React from "react";
import classes from './Underbanner.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/HomePage/Underbanner/image_01.jpg';
import image_02 from '../../../assets/img/HomePage/Underbanner/image_02.jpg';
import Button from "../../GlobalComponents/Button/Button";

const Underbanner = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={`${classes.wrap} font-17`}>
                <div className={classes.col}>
                    <div className={classes.block}>
                        <h3 className={classes.title}>
                            {t('home.underbanner.block_01.title')}
                        </h3>
                        <p className={classes.text}>
                            {t('home.underbanner.block_01.text')}
                        </p>
                    </div>
                    <img className={classes.image} src={image_01} alt='' />
                    <div className={classes.block}>
                        <h3 className={classes.title}>
                            {t('home.underbanner.block_02.title')}
                        </h3>
                        <p className={classes.text}>
                            {t('home.underbanner.block_02.text')}
                        </p>
                    </div>
                </div>
                <div className={classes.col}>
                    <img className={classes.image} src={image_02} alt='' />
                    <div className={classes.block}>
                        <h3 className={classes.title}>
                            {t('home.underbanner.block_03.title')}
                        </h3>
                        <p className={classes.text}>
                            {t('home.underbanner.block_03.text')}
                        </p>
                    </div>
                    <div className={classes.btn}>
                        <Button />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Underbanner;
