import React from "react";
import classes from './Invest.module.scss';
import { useTranslation } from "react-i18next";
import mark from '../../../assets/img/list_mark.svg';
import { Fade } from "react-reveal";

const list = [
    {
        subtitle: 'home.invest.list.li_01.subtitle',
        text: 'home.invest.list.li_01.text',
    },
    {
        subtitle: 'home.invest.list.li_02.subtitle',
        text: 'home.invest.list.li_02.text',
    },
    {
        subtitle: 'home.invest.list.li_03.subtitle',
        text: 'home.invest.list.li_03.text',
    },
    {
        subtitle: 'home.invest.list.li_04.subtitle',
        text: 'home.invest.list.li_04.text',
    },
];

const Invest = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.head}>
                    <Fade left>
                        <h2 className={`${classes.title_01} font-125`}>
                            {t('home.invest.title_01')}
                        </h2>
                    </Fade>
                    <Fade left>
                    <h3 className={`${classes.title_02} font-50`}>
                        {t('home.invest.title_02')}
                    </h3>
                    </Fade>
                    <Fade left>
                    <h4 className={`${classes.title_03} font-35`}>
                        {t('home.invest.title_03')}
                    </h4>
                    </Fade>
                </div>
                <ul className={`${classes.list} font-17`}>
                    {list.map((li, index) =>
                        <li key={index} className={classes.item}>
                            <img className={classes.mark} src={mark} alt='' />
                            <div className={classes.content}>
                                <h4 className={classes.subtitle}>
                                    <b>
                                        {t(li.subtitle)}
                                    </b>
                                </h4>
                                <p className={classes.text}>
                                    {t(li.text)}
                                </p>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </section>
    );
};

export default Invest;
