import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from '../../GlobalComponents/Button/Button';
import image from '../../../assets/img/HomePage/Banner/image.png';
import cube_01 from '../../../assets/img/HomePage/Banner/cube_01.png';
import cube_02 from '../../../assets/img/HomePage/Banner/cube_02.png';
import coin_01 from '../../../assets/img/HomePage/Banner/coin_01.png';
import coin_02 from '../../../assets/img/HomePage/Banner/coin_02.png';
import coin_03 from '../../../assets/img/HomePage/Banner/coin_03.png';
import { Fade } from "react-reveal";
import Marquee from "react-fast-marquee";

const list = [
    {
        title: '61',
        text: 'home.banner.list.li_01',
    },
    {
        title: '9',
        text: 'home.banner.list.li_02',
    },
    {
        title: '350',
        text: 'home.banner.list.li_03',
    },
    {
        title: '5,000+',
        text: 'home.banner.list.li_04',
    },
    {
        title: '100+',
        text: 'home.banner.list.li_05',
    },
    {
        title: '27',
        text: 'home.banner.list.li_06',
    },
    {
        title: '100+',
        text: 'home.banner.list.li_07',
    },
];

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.container}>
            <div className={classes.wrap}>
                <div className={`container ${classes.cnt}`}>
                    <div className={classes.body}>
                        <div className={classes.content}>
                            <h1 className={classes.head}>
                                <Fade top>
                                    <span className={`${classes.title} font-140`}>
                                        {t('home.banner.title')}
                                    </span>
                                </Fade>
                                <Fade left>
                                    <span className={`${classes.subtitle} font-70`}>
                                        {t('home.banner.subtitle')}
                                    </span>
                                </Fade>
                            </h1>
                            <div className={`${classes.text} font-17 txt-container`}>
                                <p>
                                    {t('home.banner.text_01')}
                                </p>
                                <p>
                                    {t('home.banner.text_02')}
                                </p>
                            </div>
                            <div className={`${classes.btn} mt-btn`}>
                                <Button />
                            </div>
                        </div>
                        <div className={classes.images}>
                            <img className={classes.image} src={image} alt='' />
                            <Fade left>
                                <img className={`${classes.item} ${classes.coin_01}`} src={coin_01} alt='' />
                            </Fade>
                            <Fade left bottom>
                                <img className={`${classes.item} ${classes.coin_02}`} src={coin_02} alt='' />
                            </Fade>
                            <Fade left top>
                                <img className={`${classes.item} ${classes.coin_03}`} src={coin_03} alt='' />
                            </Fade>
                            <Fade left>
                                <img className={`${classes.item} ${classes.cube_01}`} src={cube_01} alt='' />
                            </Fade>
                            <Fade right top>
                                <img className={`${classes.item} ${classes.cube_02}`} src={cube_02} alt='' />
                            </Fade>
                        </div>
                    </div>
                </div>
                <div className={classes.marquee}>
                    <Marquee autoFill>
                        <div className={classes.list}>
                            {list.map((card, index) =>
                                <div key={index} className={classes.card}>
                                    <p className={`${classes.cardTitle} font-50`}>
                                        {card.title}
                                    </p>
                                    <p className={`${classes.cardText} font-17`}>
                                        {t(card.text)}
                                    </p>
                                </div>
                            )}
                        </div>
                    </Marquee>
                </div>
            </div>
        </section>
    );
};

export default Banner;
