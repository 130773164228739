import React, { useState } from "react";
import classes from './Tabs.module.scss';
import { useTranslation } from "react-i18next";
import mark from '../../../assets/img/list_mark.svg';
import { Fade } from "react-reveal";

const tabs = [
    {
        title: 'instruments.tabs.tab_01.title',
        text: 'instruments.tabs.tab_01.text',
        blocks: [
            {
                subtitle: 'instruments.tabs.tab_01.block_01.title',
                text: 'instruments.tabs.tab_01.block_01.text',
            },
        ],
        sections: [
            {
                title: 'instruments.tabs.tab_01.section_01.title',
                text: [
                    'instruments.tabs.tab_01.section_01.text_01',
                ],
                list: [
                    {
                        subtitle: 'instruments.tabs.tab_01.section_01.list.li_01.subtitle',
                        text: 'instruments.tabs.tab_01.section_01.list.li_01.text',
                    },
                    {
                        subtitle: 'instruments.tabs.tab_01.section_01.list.li_02.subtitle',
                        text: 'instruments.tabs.tab_01.section_01.list.li_02.text',
                    },
                    {
                        subtitle: 'instruments.tabs.tab_01.section_01.list.li_03.subtitle',
                        text: 'instruments.tabs.tab_01.section_01.list.li_03.text',
                    },
                    {
                        subtitle: 'instruments.tabs.tab_01.section_01.list.li_04.subtitle',
                        text: 'instruments.tabs.tab_01.section_01.list.li_04.text',
                    },
                ],
            },
        ],
    },
    {
        title: 'instruments.tabs.tab_02.title',
        text: 'instruments.tabs.tab_02.text',
        blocks: [
            {
                subtitle: 'instruments.tabs.tab_02.block_01.title',
                text: 'instruments.tabs.tab_02.block_01.text',
            },
            {
                subtitle: 'instruments.tabs.tab_02.block_02.title',
                text: 'instruments.tabs.tab_02.block_02.text',
            },
        ],
        sections: [
            {
                title: 'instruments.tabs.tab_02.section_01.title',
                text: [
                    'instruments.tabs.tab_02.section_01.text_01',
                ],
                list: [
                    {
                        subtitle: 'instruments.tabs.tab_02.section_01.list.li_01.subtitle',
                        text: 'instruments.tabs.tab_02.section_01.list.li_01.text',
                    },
                    {
                        subtitle: 'instruments.tabs.tab_02.section_01.list.li_02.subtitle',
                        text: 'instruments.tabs.tab_02.section_01.list.li_02.text',
                    },
                    {
                        subtitle: 'instruments.tabs.tab_02.section_01.list.li_03.subtitle',
                        text: 'instruments.tabs.tab_02.section_01.list.li_03.text',
                    },
                    {
                        subtitle: 'instruments.tabs.tab_02.section_01.list.li_04.subtitle',
                        text: 'instruments.tabs.tab_02.section_01.list.li_04.text',
                    },
                ],
            },
            {
                title: 'instruments.tabs.tab_02.section_02.title',
                text: [
                    'instruments.tabs.tab_02.section_02.text_01',
                    'instruments.tabs.tab_02.section_02.text_02',
                ],
            },
        ],
    },
    {
        title: 'instruments.tabs.tab_03.title',
        text: 'instruments.tabs.tab_03.text',
        blocks: [
            {
                subtitle: 'instruments.tabs.tab_03.block_01.title',
                text: 'instruments.tabs.tab_03.block_01.text',
            },
            {
                subtitle: 'instruments.tabs.tab_03.block_02.title',
                text: 'instruments.tabs.tab_03.block_02.text',
            },
        ],
        sections: [
            {
                title: 'instruments.tabs.tab_03.section_01.title',
                text: [
                    'instruments.tabs.tab_03.section_01.text_01',
                ],
                list: [
                    {
                        subtitle: 'instruments.tabs.tab_03.section_01.list.li_01.subtitle',
                        text: 'instruments.tabs.tab_03.section_01.list.li_01.text',
                    },
                    {
                        subtitle: 'instruments.tabs.tab_03.section_01.list.li_02.subtitle',
                        text: 'instruments.tabs.tab_03.section_01.list.li_02.text',
                    },
                    {
                        subtitle: 'instruments.tabs.tab_03.section_01.list.li_03.subtitle',
                        text: 'instruments.tabs.tab_03.section_01.list.li_03.text',
                    },
                    {
                        subtitle: 'instruments.tabs.tab_03.section_01.list.li_04.subtitle',
                        text: 'instruments.tabs.tab_03.section_01.list.li_04.text',
                    },
                ],
            },
        ],
    },
    {
        title: 'instruments.tabs.tab_04.title',
        text: 'instruments.tabs.tab_04.text',
        blocks: [
            {
                subtitle: 'instruments.tabs.tab_04.block_01.title',
                text: 'instruments.tabs.tab_04.block_01.text',
            },
            {
                subtitle: 'instruments.tabs.tab_04.block_02.title',
                text: 'instruments.tabs.tab_04.block_02.text',
            },
        ],
        sections: [
            {
                title: 'instruments.tabs.tab_04.section_01.title',
                text: [
                    'instruments.tabs.tab_04.section_01.text_01',
                ],
                list: [
                    {
                        subtitle: 'instruments.tabs.tab_04.section_01.list.li_01.subtitle',
                        text: 'instruments.tabs.tab_04.section_01.list.li_01.text',
                    },
                    {
                        subtitle: 'instruments.tabs.tab_04.section_01.list.li_02.subtitle',
                        text: 'instruments.tabs.tab_04.section_01.list.li_02.text',
                    },
                    {
                        subtitle: 'instruments.tabs.tab_04.section_01.list.li_03.subtitle',
                        text: 'instruments.tabs.tab_04.section_01.list.li_03.text',
                    },
                ],
            },
        ],
    },
    {
        title: 'instruments.tabs.tab_05.title',
        text: 'instruments.tabs.tab_05.text',
        blocks: [
            {
                subtitle: 'instruments.tabs.tab_05.block_01.title',
                text: 'instruments.tabs.tab_05.block_01.text',
            },
            {
                subtitle: 'instruments.tabs.tab_05.block_02.title',
                text: 'instruments.tabs.tab_05.block_02.text',
            },
        ],
    },
    {
        title: 'instruments.tabs.tab_06.title',
        text: 'instruments.tabs.tab_06.text',
        blocks: [
            {
                subtitle: 'instruments.tabs.tab_06.block_01.title',
                text: 'instruments.tabs.tab_06.block_01.text',
            },
            {
                subtitle: 'instruments.tabs.tab_06.block_02.title',
                text: 'instruments.tabs.tab_06.block_02.text',
            },
        ],
        sections: [
            {
                title: 'instruments.tabs.tab_06.section_01.title',
                text: [
                    'instruments.tabs.tab_06.section_01.text_01',
                ],
                list: [
                    {
                        subtitle: 'instruments.tabs.tab_06.section_01.list.li_01.subtitle',
                        text: 'instruments.tabs.tab_06.section_01.list.li_01.text',
                    },
                    {
                        subtitle: 'instruments.tabs.tab_06.section_01.list.li_02.subtitle',
                        text: 'instruments.tabs.tab_06.section_01.list.li_02.text',
                    },
                    {
                        subtitle: 'instruments.tabs.tab_06.section_01.list.li_03.subtitle',
                        text: 'instruments.tabs.tab_06.section_01.list.li_03.text',
                    },
                ],
            },
        ],
    },
];

const Tabs = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.buttons}>
                    {tabs.map((tab, index) =>
                    <Fade key={index} left>
                        <div
                            onClick={() => setActive(index)}
                            className={`${classes.button} ${active === index ? classes.active : ''} font-25 no-select`}
                        >
                            {t(tab.title)}
                        </div>
                    </Fade>
                    )}
                </div>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-50`}>
                        {t(tabs[active].title)}
                    </h2>
                    <p className={`${classes.text} font-17`}>
                        {t(tabs[active].text)}
                    </p>
                    <div className={`${classes.blocks} font-17`}>
                        {tabs[active].blocks.map((block, blockIndex) =>
                            <div key={blockIndex} className={classes.block}>
                                <h4>
                                    <b>
                                        {t(block.subtitle)}
                                    </b>
                                </h4>
                                <p>
                                    {t(block.text)}
                                </p>
                            </div>
                        )}
                    </div>
                    {tabs[active].sections &&
                        <div className={classes.sections}>
                            {tabs[active].sections.map((section, sectionIndex) =>
                                <div key={sectionIndex} className={classes.section}>
                                    <h3 className={`${classes.sectionTitle} font-35`}>
                                        {t(section.title)}
                                    </h3>
                                    <div className={`${classes.sectionText} font-17 txt-container`}>
                                        {section.text.map((txt, txtIndex) =>
                                            <p key={txtIndex}>
                                                {t(txt)}
                                            </p>
                                        )}
                                    </div>
                                    {section.list &&
                                        <ul className={`${classes.list} font-17`}>
                                            {section.list.map((li, index) =>
                                                <li key={index} className={classes.item}>
                                                    <img className={classes.mark} src={mark} alt='' />
                                                    <div className={classes.content}>
                                                        <h4 className={classes.subtitle}>
                                                            <b>
                                                                {t(li.subtitle)}
                                                            </b>
                                                        </h4>
                                                        <p className={classes.text}>
                                                            {t(li.text)}
                                                        </p>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    }
                                </div>
                            )}
                        </div>
                    }
                </div>
            </div>
        </section>
    );
};

export default Tabs;
