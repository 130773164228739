import React, { useState, useEffect } from "react";
import classes from './Why.module.scss';
import image from '../../../assets/img/IpoPage/why.jpg';
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";
import Button from "../../GlobalComponents/Button/Button";

const list = [
    'ipo.why.li_01',
    'ipo.why.li_02',
    'ipo.why.li_03',
    'ipo.why.li_04',
    'ipo.why.li_05',
];

const Why = () => {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <Fade right>
                        <h2 className={`${classes.title} font-50`}>
                            {t('ipo.why.title')}
                        </h2>
                    </Fade>
                    <ul className={classes.list}>
                        {list.map((li, index) =>
                            <Fade key={index} right>
                                <li className={classes.item}>
                                    <svg className={classes.mark} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M16.2527 10.1568L1.01752 18.9528L1.01752 1.36075L16.2527 10.1568Z" fill="#691AFF" />
                                    </svg>
                                    <p>
                                        {t(li)}
                                    </p>
                                </li>
                            </Fade>
                        )}
                    </ul>
                    <div className="mt-btn">
                        <Button />
                    </div>
                </div>
                {windowWidth > 992 &&
                    <img className={classes.image} src={image} alt='' />
                }
            </div>
        </section>
    );
};

export default Why;
