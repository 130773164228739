import React from "react";
import classes from './Enter.module.scss';
import { Trans, useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

const cards = [
    {
        subtitle: 'accounts.enter.card_01.subtitle',
        text: 'accounts.enter.card_01.text',
    },
    {
        subtitle: 'accounts.enter.card_02.subtitle',
        text: 'accounts.enter.card_02.text',
    },
    {
        subtitle: 'accounts.enter.card_03.subtitle',
        text: 'accounts.enter.card_03.text',
    },
];

const Enter = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-35`}>
                    <Trans>
                        {t('accounts.enter.title')}
                    </Trans>
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <Fade key={index} bottom>
                            <div className={classes.card}>
                                <p className={classes.num}>
                                    0{index + 1}
                                </p>
                                <div className={`${classes.content} font-17`}>
                                    <h4 className={classes.subtitle}>
                                        <b>
                                            {t(card.subtitle)}
                                        </b>
                                    </h4>
                                    <p className={classes.text}>
                                        {t(card.text)}
                                    </p>
                                </div>
                            </div>
                        </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Enter;
