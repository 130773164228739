import React, { useState } from "react";
import classes from './Faq.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const tabs = [
    {
        title: 'promotions.faq.tab_01.title',
        text: 'promotions.faq.tab_01.text',
    },
    {
        title: 'promotions.faq.tab_02.title',
        text: 'promotions.faq.tab_02.text',
    },
    {
        title: 'promotions.faq.tab_03.title',
        text: 'promotions.faq.tab_03.text',
    },
    {
        title: 'promotions.faq.tab_04.title',
        text: 'promotions.faq.tab_04.text',
    },
];

const Faq = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-50`}>
                        FAQ
                    </h2>
                    <p className={`${classes.text} font-17`}>
                        <b>
                            {t('promotions.faq.text')}
                        </b>
                    </p>
                    <div className={`${classes.btn} mt-btn`}>
                        <Button />
                    </div>
                </div>
                <div className={classes.tabs}>
                    {tabs.map((tab, index) =>
                        <Fade key={index} bottom>
                            <div                                
                                onClick={() => setActive(index)}
                                className={`${classes.tab} ${active === index ? classes.active : ''}`}
                            >
                                <div className={classes.head}>
                                    <h4 className={`${classes.subtitle} font-20`}>
                                        {t(tab.title)}
                                    </h4>
                                    <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" fill="none">
                                        <path d="M13.8088 39.506L36.8678 17.33" stroke="#4F4F4F" stroke-width="4.57029" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M36.4263 39.9473L36.8677 17.3298L14.2503 16.8884" stroke="#4F4F4F" stroke-width="4.57029" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <p className={`${classes.text} font-17`}>
                                    {t(tab.text)}
                                </p>
                            </div>
                        </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Faq;
