import React from "react";
import classes from './Methods.module.scss';
import { useTranslation } from "react-i18next";
import logo_01 from '../../../assets/img/AboutPage/logo_01.png';
import logo_02 from '../../../assets/img/AboutPage/logo_02.png';
import logo_03 from '../../../assets/img/AboutPage/logo_03.png';
import logo_04 from '../../../assets/img/AboutPage/logo_04.png';
import logo_05 from '../../../assets/img/AboutPage/logo_05.png';

const logos = [
    logo_01,
    logo_02,
    logo_03,
    logo_04,
    logo_05,
];

const Methods = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-50`}>
                    {t('about.methods.title')}
                </h2>
                <div className={classes.logos}>
                    {logos.map((logo, index) =>
                        <img key={index} className={classes.logo} src={logo} alt='' />
                    )}
                </div>
            </div>
        </section>
    );
};

export default Methods;
