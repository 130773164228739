import React from "react";
import classes from './Table.module.scss';
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

const rows = [
    [
        '',
        'promotions.table.col_02',
        'promotions.table.col_03',
    ],
    [
        'promotions.table.row_01',
        '$500',
        '$500',
    ],
    [
        'promotions.table.row_02',
        '$0',
        '$150',
    ],
    [
        'promotions.table.row_03',
        '$500',
        '$650',
    ],
    [
        'promotions.table.row_04',
        '2.5',
        '3.25',
    ],
    [
        'promotions.table.row_05',
        '$250',
        '$325',
    ],
];

const Table = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.table}>
                    <table>
                        <tbody>
                            {rows.map((row, index) =>
                                <tr key={index}>
                                    {row.map((col, colIndex) =>
                                        <td key={colIndex}>
                                            {t(col)}
                                        </td>
                                    )}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default Table;
