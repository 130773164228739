import React from "react";
import classes from './Trader.module.scss';
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

const rows = [
    [
        'home.trader.acc_type',
        'home.trader.retail',
        'home.trader.professional',
    ],
    [
        'home.trader.row_01',
        'home.trader.leverage_rate',
        'home.trader.leverage_rate',
    ],
    [
        'home.trader.row_02',
        ' 1:30',
        ' 1:200',
    ],
    [
        'home.trader.row_03',
        ' 1:20',
        ' 1:100',
    ],
    [
        'home.trader.row_04',
        ' 1:10',
        ' 1:40',
    ],
    [
        'home.trader.row_05',
        ' 1:5',
        ' 1:20',
    ],
    [
        'home.trader.row_06',
        ' 1:2',
        ' 1:5',
    ],
];

const Trader = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <Fade top>
                    <h2 className={`${classes.title} font-50`}>
                        {t('home.trader.title')}
                    </h2>
                </Fade>
                <div className={classes.table}>
                    <table>
                        <tbody>
                            {rows.map((row, index) =>
                                <tr key={index}>
                                    {row.map((col, colIndex) =>
                                        <td key={colIndex}>
                                            {t(col)}
                                        </td>
                                    )}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default Trader;
