import React, { useEffect, useState } from "react";
import classes from './Transparent.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/InstrumentsPage/Transparent/image_01.jpg';
import image_02 from '../../../assets/img/InstrumentsPage/Transparent/image_02.jpg';
import image_03 from '../../../assets/img/InstrumentsPage/Transparent/image_03.jpg';
import image_04 from '../../../assets/img/InstrumentsPage/Transparent/image_04.jpg';
import { Fade } from "react-reveal";

const cards = [
    {
        image: image_01,
        title: 'instruments.transparent.card_01.title',
        text: 'instruments.transparent.card_01.text',
    },
    {
        image: image_02,
        title: 'instruments.transparent.card_02.title',
        text: 'instruments.transparent.card_02.text',
    },
    {
        image: image_03,
        title: 'instruments.transparent.card_03.title',
        text: 'instruments.transparent.card_03.text',
    },
    {
        image: image_04,
        title: 'instruments.transparent.card_04.title',
        text: 'instruments.transparent.card_04.text',
    },
];

const Transparent = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const setMarkPosition = (value) => {
        const mark = document.getElementById('mark');
        windowWidth > 480
            ? mark.style.top = `${value * 25}%`
            : mark.style.left = `${value * 25}%`
    };

    useEffect(() => {
        setMarkPosition(active);
    }, [active]);

    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <Fade left>
                    <div className={classes.status}>
                        <div className={classes.line}>
                            <div id="mark" className={classes.mark}></div>
                        </div>
                        <p className={`${classes.num} font-17`}>
                            <b>{active + 1}</b>/4
                        </p>
                    </div>
                </Fade>
                {windowWidth > 850 &&
                    <div className={classes.images}>
                        {cards.map((card, index) =>
                            <img
                                key={index}
                                className={`${classes.image} ${active === index ? classes.active : ''}`}
                                src={card.image}
                                alt=''
                            />
                        )}
                    </div>
                }
                <div className={classes.buttons}>
                    {cards.map((card, index) =>
                        <div
                            key={index}
                            className={`${classes.button} ${active === index ? classes.active : ''} font-25 no-select`}
                            onClick={() => setActive(index)}
                        >
                            {t(card.title)}
                        </div>
                    )}
                </div>
                <p className={`${classes.text} font-17`}>
                    <Trans>
                        {t(cards[active].text)}
                    </Trans>
                </p>
            </div>
        </section>
    );
};

export default Transparent;
