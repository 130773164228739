import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from '../../GlobalComponents/Button/Button';
import image from '../../../assets/img/PromotionsPage/Banner/image.jpg';
import coin_01 from '../../../assets/img/PromotionsPage/Banner/coin_01.png';
import coin_02 from '../../../assets/img/PromotionsPage/Banner/coin_02.png';
import percent from '../../../assets/img/PromotionsPage/Banner/percent.png';
import { Fade } from "react-reveal";
import Marquee from "react-fast-marquee";

const list = [
    {
        title: '0.0',
        text: 'promotions.banner.list.li_01',
    },
    {
        title: '1:500',
        text: 'promotions.banner.list.li_02',
    },
    {
        title: '0.01',
        text: 'promotions.banner.list.li_03',
    },
    {
        title: '+5000',
        text: 'promotions.banner.list.li_04',
    },
];

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.container}>
            <div className={classes.wrap}>
                <div className={`container ${classes.cnt}`}>
                    <div className={classes.body}>
                        <div className={classes.content}>
                            <h1 className={classes.head}>
                                <Fade top>
                                    <span className={`${classes.title} font-140`}>
                                        {t('promotions.banner.title')}
                                    </span>
                                </Fade>
                                <Fade left>
                                    <span className={`${classes.subtitle} font-50`}>
                                        {t('promotions.banner.subtitle')}
                                    </span>
                                </Fade>
                            </h1>
                            <div className={`${classes.text} font-17 txt-container`}>
                                <p>
                                    {t('promotions.banner.text_01')}
                                </p>
                                <p>
                                    {t('promotions.banner.text_02')}
                                </p>
                            </div>
                            <div className={`${classes.btn} mt-btn`}>
                                <Button />
                            </div>
                        </div>
                        <div className={classes.images}>
                            <img className={classes.image} src={image} alt='' />
                            <Fade left bottom>
                                <img className={`${classes.item} ${classes.coin_01}`} src={coin_01} alt='' />
                            </Fade>
                            <Fade right top>
                                <img className={`${classes.item} ${classes.coin_02}`} src={coin_02} alt='' />
                            </Fade>
                            <Fade right>
                                <img className={`${classes.item} ${classes.percent}`} src={percent} alt='' />
                            </Fade>
                        </div>
                    </div>
                </div>
                <div className={classes.marquee}>
                    <Marquee autoFill>
                        <div className={classes.list}>
                            {list.map((card, index) =>
                                <div key={index} className={classes.card}>
                                    <p className={`${classes.cardTitle} font-50`}>
                                        {card.title}
                                    </p>
                                    <p className={`${classes.cardText} font-17`}>
                                        {t(card.text)}
                                    </p>
                                </div>
                            )}
                        </div>
                    </Marquee>
                </div>
            </div>
        </section>
    );
};

export default Banner;
