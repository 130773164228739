import React from "react";
import classes from './ShouldKnow.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import image from '../../../assets/img/HomePage/should_know.jpg';
import { Fade } from "react-reveal";

const ShouldKnow = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.image}>
                    <img src={image} alt='' />
                </div>
                <div className={classes.content}>
                    <div className={classes.head}>
                        <Fade right>
                            <h2 className={`${classes.title_01} font-50`}>
                                {t('home.should_know.title_01')}
                            </h2>
                        </Fade>
                        <Fade right>
                            <h3 className={`${classes.title_02} font-35`}>
                                {t('home.should_know.title_02')}
                            </h3>
                        </Fade>
                    </div>
                    <div className={classes.block}>
                        <h4 className={`${classes.subtitle} font-25`}>
                            {t('home.should_know.subtitle')}
                        </h4>
                        <div className={`${classes.text} font-17 txt-container`}>
                            <p>
                                {t('home.should_know.text_01')}
                            </p>
                            <p>
                                {t('home.should_know.text_02')}
                            </p>
                        </div>
                    </div>
                </div>
                <div className={classes.btn}>
                    <Button>
                        {t('btn.more')}
                    </Button>
                </div>
            </div>
        </section>
    );
};

export default ShouldKnow;
