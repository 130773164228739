import React, { useEffect, useState } from "react";
import classes from './Tabs.module.scss';
import { useTranslation } from "react-i18next";
import mark from '../../../assets/img/list_mark.svg';
import { Fade } from "react-reveal";

const tabs = [
    {
        title: 'about.tabs.tab_01.title',
        text: 'about.tabs.tab_01.text',
        qa: [
            {
                subtitle: 'about.tabs.tab_01.qa_01.subtitle',
                desc: 'about.tabs.tab_01.qa_01.desc',
            },
            {
                subtitle: 'about.tabs.tab_01.qa_02.subtitle',
                desc: 'about.tabs.tab_01.qa_02.desc',
            },
            {
                subtitle: 'about.tabs.tab_01.qa_03.subtitle',
                desc: 'about.tabs.tab_01.qa_03.desc',
            },
            {
                subtitle: 'about.tabs.tab_01.qa_04.subtitle',
                desc: 'about.tabs.tab_01.qa_04.desc',
            },
            {
                subtitle: 'about.tabs.tab_01.qa_05.subtitle',
                desc: 'about.tabs.tab_01.qa_05.desc',
            },
        ],
    },
    {
        title: 'about.tabs.tab_02.title',
        qa: [
            {
                subtitle: 'about.tabs.tab_02.qa_01.subtitle',
                desc: 'about.tabs.tab_02.qa_01.desc',
            },
            {
                subtitle: 'about.tabs.tab_02.qa_02.subtitle',
                desc: 'about.tabs.tab_02.qa_02.desc',
            },
            {
                subtitle: 'about.tabs.tab_02.qa_03.subtitle',
                desc: 'about.tabs.tab_02.qa_03.desc',
            },
            {
                subtitle: 'about.tabs.tab_02.qa_04.subtitle',
                desc: 'about.tabs.tab_02.qa_04.desc',
            },
            {
                subtitle: 'about.tabs.tab_02.qa_05.subtitle',
                desc: 'about.tabs.tab_02.qa_05.desc',
            },
            {
                subtitle: 'about.tabs.tab_02.qa_06.subtitle',
                desc: 'about.tabs.tab_02.qa_06.desc',
            },
            {
                subtitle: 'about.tabs.tab_02.qa_07.subtitle',
                desc: 'about.tabs.tab_02.qa_07.desc',
            },
            {
                subtitle: 'about.tabs.tab_02.qa_08.subtitle',
                desc: 'about.tabs.tab_02.qa_08.desc',
            },
            {
                subtitle: 'about.tabs.tab_02.qa_09.subtitle',
                desc: 'about.tabs.tab_02.qa_09.desc',
            },
            {
                subtitle: 'about.tabs.tab_02.qa_10.subtitle',
                desc: 'about.tabs.tab_02.qa_10.desc',
            },
            {
                subtitle: 'about.tabs.tab_02.qa_11.subtitle',
                desc: 'about.tabs.tab_02.qa_11.desc',
            },
            {
                subtitle: 'about.tabs.tab_02.qa_12.subtitle',
                desc: 'about.tabs.tab_02.qa_12.desc',
            },
        ],
    },
    {
        title: 'about.tabs.tab_03.title',
        text: 'about.tabs.tab_03.text',
    },
];

const Tabs = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);
    const [qaActive, setQaActive] = useState(0);

    useEffect(() => {
        setQaActive(0);
    }, [active]);

    return (
        <section className="container">
            <div className={classes.wrap}>
                <div className={classes.buttons}>
                    {tabs.map((tab, index) =>
                        <Fade key={index} left>
                            <div
                                onClick={() => setActive(index)}
                                className={`${classes.button} ${active === index ? classes.active : ''} font-25 no-select`}
                            >
                                {t(tab.title)}
                            </div>
                        </Fade>
                    )}
                </div>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-50`}>
                        {t(tabs[active].title)}
                    </h2>
                    {tabs[active].text &&
                        <p className={`${classes.text} font-17`}>
                            {t(tabs[active].text)}
                        </p>
                    }
                    {tabs[active].qa &&
                        <div className={classes.qa}>
                            {tabs[active].qa.map((tab, index) =>
                                <div
                                    key={index}
                                    onClick={() => setQaActive(index)}
                                    className={`${classes.tab} ${qaActive === index ? classes.active : ''}`}
                                >
                                    <div className={classes.head}>
                                        <h4 className={`${classes.subtitle} font-20`}>
                                            {t(tab.subtitle)}
                                        </h4>
                                        <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" fill="none">
                                            <path d="M13.8088 39.506L36.8678 17.33" stroke="#4F4F4F" stroke-width="4.57029" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M36.4263 39.9473L36.8677 17.3298L14.2503 16.8884" stroke="#4F4F4F" stroke-width="4.57029" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                    <p className={`${classes.desc} font-17`}>
                                        {t(tab.desc)}
                                    </p>
                                </div>
                            )}
                        </div>
                    }
                </div>
            </div>
        </section>
    );
};

export default Tabs;
