import React from "react";
import classes from "./AccountTypes.module.scss";
import img1 from "../../../assets/img/AccountsPage/AccountTypes/1.svg";
import img2 from "../../../assets/img/AccountsPage/AccountTypes/2.svg";
import img3 from "../../../assets/img/AccountsPage/AccountTypes/3.svg";
import img4 from "../../../assets/img/AccountsPage/AccountTypes/4.svg";
import imgTrue from '../../../assets/img/AccountsPage/AccountTypes/true.svg';
import imgFalse from '../../../assets/img/AccountsPage/AccountTypes/false.svg';
import { useTranslation } from "react-i18next";

const AccountTypes = () => {
  const { t } = useTranslation();
  return (
    <div className={`${classes.five} mt`}>
      <div className={classes.fiveBody}>
        <div className={`${classes.container} container font-16`}>
          <div className={classes.fiveWrap}>
            <div className={classes.fiveTable}>
              <div className={classes.fiveTableTop}>
                <p className={`${classes.fiveTableTopItem}`}>
                  {t('traders_five_label_1')}
                </p>
                <p className={`${classes.fiveTableTopItem}`}>
                  <img src={img1} alt="" />
                  Basic
                </p>
                <p className={`${classes.fiveTableTopItem}`}>
                  <img src={img2} alt="" />
                  Smart Start
                </p>
                <p className={`${classes.fiveTableTopItem}`}>
                  <img src={img3} alt="" />
                  Full Package
                </p>
                <p className={`${classes.fiveTableTopItem}`}>
                  <img src={img4} alt="" />
                  VIP
                </p>
              </div>
              <div className={classes.fiveTableRow}>
                <p className={`${classes.fiveTableRowItem}`}>
                  {t('traders_five_label_2')}
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  {t('traders_five_label_2_cell_2')}
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  <img src={imgTrue} alt=''/>
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  <img src={imgTrue} alt=''/>
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  <img src={imgTrue} alt=''/>
                </p>
              </div>
              <div className={classes.fiveTableRow}>
                <p className={`${classes.fiveTableRowItem}`}>
                  {t('traders_five_label_4')}
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  {t('traders_five_label_4_standart')}
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  {t('traders_five_label_4_standart')}
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  {t('traders_five_label_4_pro')}
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  {t('traders_five_label_4_pro')}
                </p>
              </div>
              <div className={classes.fiveTableRow}>
                <p className={`${classes.fiveTableRowItem}`}>
                  {t('traders_five_label_3')}
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  <img src={imgFalse} alt=''/>
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  <img src={imgTrue} alt=''/>
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  <img src={imgTrue} alt=''/>
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  <img src={imgTrue} alt=''/>
                </p>
              </div>
              <div className={classes.fiveTableRow}>
                <p className={`${classes.fiveTableRowItem}`}>
                  {t('traders_five_label_5')}
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  <img src={imgFalse} alt=''/>
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  <img src={imgFalse} alt=''/>
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  <img src={imgTrue} alt=''/>
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  <img src={imgTrue} alt=''/>
                </p>
              </div>
              <div className={classes.fiveTableRow}>
                <p className={`${classes.fiveTableRowItem}`}>
                  {t('traders_five_label_6')}
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  <img src={imgFalse} alt=''/>
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  <img src={imgFalse} alt=''/>
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  <img src={imgTrue} alt=''/>
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  <img src={imgTrue} alt=''/>
                </p>
              </div>
              <div className={classes.fiveTableRow}>
                <p className={`${classes.fiveTableRowItem}`}>
                  {t('traders_five_label_7')}
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  <img src={imgFalse} alt=''/>
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  <img src={imgFalse} alt=''/>
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  <img src={imgFalse} alt=''/>
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  <img src={imgTrue} alt=''/>
                </p>
              </div>
              <div className={classes.fiveTableRow}>
                <p className={`${classes.fiveTableRowItem}`}>
                  {t('traders_five_label_8')}
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  40%
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  50%
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  70%
                </p>
                <p className={`${classes.fiveTableRowItem}`}>
                  80%
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountTypes;
