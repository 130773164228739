import HomePage from '../pages/HomePage';
import InstrumentsPage from '../pages/InstrumentsPage';
import PromotionsPage from '../pages/PromotionsPage';
import AccountsPage from '../pages/AccountsPage';
import IpoPage from '../pages/IpoPage';
import AboutPage from '../pages/AboutPage';

export const routes = [
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/instruments', element: InstrumentsPage },
    { path: '/:lang/promotions', element: PromotionsPage },
    { path: '/:lang/accounts', element: AccountsPage },
    { path: '/:lang/ipos', element: IpoPage },
    { path: '/:lang/about', element: AboutPage },
];


export const headerRoutes = [
    { path: '/instruments', text: 'header.trading_instruments' },
    { path: '/promotions', text: 'header.promotions' },
    { path: '/accounts', text: 'header.account_types' },
    { path: '/ipos', text: 'header.ipos' },
    { path: '/about', text: 'header.about_us' },
];
