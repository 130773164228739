import React from "react";
import classes from './Cards.module.scss';
import { useTranslation } from "react-i18next";

const Cards = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={`${classes.row} ${classes.row_01}`}>
                    <div className={`${classes.card} ${classes.card_01}`}>
                        <h3 className={`${classes.title} font-30`}>
                            {t('ipo.cards.card_01.title')}
                        </h3>
                        <p className={`${classes.text} font-17`}>
                            {t('ipo.cards.card_01.text')}
                        </p>
                    </div>
                    <div className={`${classes.card} ${classes.card_02}`}>
                        <h3 className={`${classes.title} font-30`}>
                            {t('ipo.cards.card_02.title')}
                        </h3>
                        <p className={`${classes.text} font-17`}>
                            {t('ipo.cards.card_02.text')}
                        </p>
                    </div>
                </div>
                <div className={`${classes.row} ${classes.row_02}`}>
                    <div className={`${classes.card} ${classes.card_03}`}>
                        <h3 className={`${classes.title} font-30`}>
                            {t('ipo.cards.card_03.title')}
                        </h3>
                        <p className={`${classes.text} font-17`}>
                            {t('ipo.cards.card_03.text')}
                        </p>
                    </div>
                    <div className={`${classes.card} ${classes.card_04}`}>
                        <h3 className={`${classes.title} font-30`}>
                            {t('ipo.cards.card_04.title')}
                        </h3>
                        <p className={`${classes.text} font-17`}>
                            {t('ipo.cards.card_04.text')}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Cards;
