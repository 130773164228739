import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/InstrumentsPage/Banner/Banner";
import Transparent from "../components/InstrumentsPage/Transparent/Transparent";
import Tabs from "../components/InstrumentsPage/Tabs/Tabs";

const InstrumentsPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Lvl Singapore PTE · Trading Instruments</title>
        <meta name="title" content="Diverse Trading Instruments on Lvl Singapore PTE - Unleash Your Investment Potential" />
        <meta property="og:title" content="Diverse Trading Instruments on Lvl Singapore PTE - Unleash Your Investment Potential" />
        <meta property="twitter:title" content="Diverse Trading Instruments on Lvl Singapore PTE - Unleash Your Investment Potential" />

        <meta name="description" content="Explore a wide range of trading instruments on Lvl Singapore PTE, from cryptocurrencies to stocks. Stay ahead with real-time data, advanced analytics, and make informed decisions that shape your financial success." />
        <meta property="og:description" content="Explore a wide range of trading instruments on Lvl Singapore PTE, from cryptocurrencies to stocks. Stay ahead with real-time data, advanced analytics, and make informed decisions that shape your financial success." />
        <meta property="twitter:description" content="Explore a wide range of trading instruments on Lvl Singapore PTE, from cryptocurrencies to stocks. Stay ahead with real-time data, advanced analytics, and make informed decisions that shape your financial success." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Transparent />
        <Tabs />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default InstrumentsPage;
