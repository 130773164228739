import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/AccountsPage/Banner/Banner";
import AccountTypes from "../components/AccountsPage/AccountTypes/AccountTypes";
import Explore from "../components/AccountsPage/Explore/Explore";
import Seamless from "../components/AccountsPage/Seamless/Seamless";
import Achieve from "../components/AccountsPage/Achieve/Achieve";
import Enter from "../components/AccountsPage/Enter/Enter";

const AccountsPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Lvl Singapore PTE · Account Types</title>
        <meta name="title" content="Tailored Account Types for Every Trader - Choose Your Path with Lvl Singapore PTE" />
        <meta property="og:title" content="Tailored Account Types for Every Trader - Choose Your Path with Lvl Singapore PTE" />
        <meta property="twitter:title" content="Tailored Account Types for Every Trader - Choose Your Path with Lvl Singapore PTE" />

        <meta name="description" content="Discover personalized account types on Lvl Singapore PTE designed to suit your trading style. Whether you're a novice or seasoned trader, our diverse account options empower you to take control of your financial future." />
        <meta property="og:description" content="Discover personalized account types on Lvl Singapore PTE designed to suit your trading style. Whether you're a novice or seasoned trader, our diverse account options empower you to take control of your financial future." />
        <meta property="twitter:description" content="Discover personalized account types on Lvl Singapore PTE designed to suit your trading style. Whether you're a novice or seasoned trader, our diverse account options empower you to take control of your financial future." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <AccountTypes />
        <Explore />
        <Seamless />
        <Achieve />
        <Enter />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AccountsPage;
