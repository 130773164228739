import React from "react";
import classes from './HowWork.module.scss';
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";

const cards = [
    {
        subtitle: 'promotions.how_work.card_01.subtitle',
        desc: 'promotions.how_work.card_01.desc',
    },
    {
        subtitle: 'promotions.how_work.card_02.subtitle',
        desc: 'promotions.how_work.card_02.desc',
    },
    {
        subtitle: 'promotions.how_work.card_03.subtitle',
        desc: 'promotions.how_work.card_03.desc',
    },
    {
        subtitle: 'promotions.how_work.card_04.subtitle',
        desc: 'promotions.how_work.card_04.desc',
    },
];

const HowWork = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.head}>
                    <Fade left>
                        <h2 className={`${classes.title} font-70`}>
                            {t('promotions.how_work.title')}
                        </h2>
                    </Fade>
                    <p className={`${classes.text} font-17`}>
                        {t('promotions.how_work.text')}
                    </p>
                </div>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <Fade
                            key={index}
                            left={(index === 0 || index === 2) ? true : false}
                            right={(index === 1 || index === 3) ? true : false}
                        >
                            <div className={classes.card}>
                                <div className={classes.num}>
                                    0{index + 1}
                                </div>
                                <div className={classes.content}>
                                    <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16" fill="none">
                                        <path d="M9.62402 15.874L1.50503 1.81152L17.743 1.81152L9.62402 15.874Z" fill="white" />
                                    </svg>
                                    <h4 className={classes.subtitle}>
                                        <b>
                                            {t(card.subtitle)}
                                        </b>
                                    </h4>
                                    <p className={`${classes.desc} font-17`}>
                                        {t(card.desc)}
                                    </p>
                                </div>
                            </div>
                        </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default HowWork;
