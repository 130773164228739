import React, { useState } from "react";
import classes from './Achieve.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import { Fade } from "react-reveal";

const tabs = [
    {
        heading: 'accounts.achieve.tab_01.heading',
        desc: 'accounts.achieve.tab_01.desc',
    },
    {
        heading: 'accounts.achieve.tab_02.heading',
        desc: 'accounts.achieve.tab_02.desc',
    },
    {
        heading: 'accounts.achieve.tab_03.heading',
        desc: 'accounts.achieve.tab_03.desc',
    },
    {
        heading: 'accounts.achieve.tab_04.heading',
        desc: 'accounts.achieve.tab_04.desc',
    },
    {
        heading: 'accounts.achieve.tab_05.heading',
        desc: 'accounts.achieve.tab_05.desc',
    },
    {
        heading: 'accounts.achieve.tab_06.heading',
        desc: 'accounts.achieve.tab_06.desc',
    },
];

const Achieve = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-50`}>
                        {t('accounts.achieve.title')}
                    </h2>
                    <h3 className={`${classes.subtitle} font-35`}>
                       {t('accounts.achieve.subtitle')}
                    </h3>
                    <p className={`${classes.text} font-17`}>
                        {t('accounts.achieve.text')}
                    </p>
                    <div className={`${classes.btn} mt-btn`}>
                        <Button />
                    </div>
                </div>
                <div className={classes.tabs}>
                    {tabs.map((tab, index) =>
                        <Fade key={index} bottom>
                            <div                                
                                onClick={() => setActive(index)}
                                className={`${classes.tab} ${active === index ? classes.active : ''}`}
                            >
                                <div className={classes.head}>
                                    <h4 className={`${classes.heading} font-20`}>
                                        {t(tab.heading)}
                                    </h4>
                                    <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" fill="none">
                                        <path d="M13.8088 39.506L36.8678 17.33" stroke="#4F4F4F" stroke-width="4.57029" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M36.4263 39.9473L36.8677 17.3298L14.2503 16.8884" stroke="#4F4F4F" stroke-width="4.57029" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <p className={`${classes.desc} font-17`}>
                                    {t(tab.desc)}
                                </p>
                            </div>
                        </Fade>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Achieve;
