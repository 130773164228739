import React from "react";
import classes from "./Button.module.scss";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Button = ({ children, login, href, dark }) => {
  const { t } = useTranslation();
  const { lang } = useParams();
  return (
    <Link
      className={`${classes.button} ${dark ? classes.dark : ''} no-select`}
      to={!href
        ? `${process.env.REACT_APP_CABINET_URL}/${login ? 'login' : 'signup'}?lang=${lang}`
        : `/${lang}/${href}`
      }
    >
      <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
        <path d="M1.31543 15.7246L15.8019 1.33777" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.36523 1.28711L15.8019 1.33695L15.7521 15.7736" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      <span className={classes.text}>
        {children
          ? children
          : t('btn.start_now')
        }
      </span>
    </Link>
  );
};

export default Button;
