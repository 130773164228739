import React, { useEffect, useState } from "react";
import classes from './GetBonus.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/PromotionsPage/get_bonus.jpg';
import { Fade } from "react-reveal";

const GetBonus = () => {
    const { t } = useTranslation();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <Fade right>
                        <h2 className={`${classes.title} font-35`}>
                            <Trans>
                                {t('promotions.get_bonus.title')}
                            </Trans>
                        </h2>
                    </Fade>
                    <p className={`${classes.text} font-17`}>
                        {t('promotions.get_bonus.text')}
                    </p>
                    <p className={`${classes.desc} font-17`}>
                        <b>
                            {t('promotions.get_bonus.desc')}
                        </b>
                    </p>
                </div>
                {windowWidth > 850 &&
                    <Fade left>
                        <img className={classes.image} src={image} alt='' />
                    </Fade>
                }
            </div>
        </section>
    );
};

export default GetBonus;
