import React from "react";
import classes from './Priority.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/IpoPage/priority.png';
import { Fade } from "react-reveal";

const Priority = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <Fade top>
                        <h2 className={`${classes.title} font-50`}>
                            {t('ipo.priority.title')}
                        </h2>
                    </Fade>
                    <h3 className={`${classes.subtitle} font-35`}>
                        {t('ipo.priority.subtitle')}
                    </h3>
                    <div className={`${classes.text} txt-container font-17`}>
                        <p>
                            {t('ipo.priority.text_01')}
                        </p>
                        <p>
                            {t('ipo.priority.text_02')}
                        </p>
                    </div>
                </div>
                <img className={classes.image} src={image} alt='' />
            </div>
        </section>
    );
};

export default Priority;
