import React from "react";
import classes from './Explore.module.scss';
import { useTranslation } from "react-i18next";
import Marquee from "react-fast-marquee";
import { Fade } from "react-reveal";

const marquee_01 = [
    {
        heading: 'accounts.explore.card_01.heading',
        text: 'accounts.explore.card_01.text',
    },
    {
        heading: 'accounts.explore.card_02.heading',
        text: 'accounts.explore.card_02.text',
    },
    {
        heading: 'accounts.explore.card_03.heading',
        text: 'accounts.explore.card_03.text',
    },
    {
        heading: 'accounts.explore.card_04.heading',
        text: 'accounts.explore.card_04.text',
    },
];

const marquee_02 = [
    {
        heading: 'accounts.explore.card_05.heading',
        text: 'accounts.explore.card_05.text',
    },
    {
        heading: 'accounts.explore.card_06.heading',
        text: 'accounts.explore.card_06.text',
    },
    {
        heading: 'accounts.explore.card_07.heading',
        text: 'accounts.explore.card_07.text',
    },
];

const Explore = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.wrap}>
            <div className="mt container">
                <Fade left>
                    <h2 className={classes.head}>
                        <span className={`${classes.title} font-125`}>
                            {t('accounts.explore.title')}
                        </span>&nbsp;&nbsp;&nbsp;
                        <span className={`${classes.subtitle} font-50`}>
                            {t('accounts.explore.subtitle')}
                        </span>
                    </h2>
                </Fade>
            </div>
            <div className={classes.marquees}>
                <Marquee autoFill>
                    <div className={classes.marquee}>
                        {marquee_01.map((card, index) =>
                            <div key={index} className={classes.card}>
                                <p className={`${classes.heading} font-30`}>
                                    {t(card.heading)}
                                </p>
                                <p className={classes.text}>
                                    {t(card.text)}
                                </p>
                            </div>
                        )}
                    </div>
                </Marquee>
                <Marquee autoFill direction="right">
                    <div className={classes.marquee}>
                        {marquee_02.map((card, index) =>
                            <div key={index} className={classes.card}>
                                <p className={`${classes.heading} font-30`}>
                                    {t(card.heading)}
                                </p>
                                <p className={classes.text}>
                                    {t(card.text)}
                                </p>
                            </div>
                        )}
                    </div>
                </Marquee>
            </div>
        </section>
    );
};

export default Explore;
